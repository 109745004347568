import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaStar } from "react-icons/fa";

const Reviews = () => {
  const reviewPlatforms = [
    {
      id: 1,
      platform: "Apple App Store",
      reviews: "800+ reviews",
      rating: 4.8,
    },
    {
      id: 2,
      platform: "Google Play Store",
      reviews: "200+ reviews",
      rating: 4.8,
    },
  ];

  const adaptableCards = [
    {
      id: 1,
      rating: 5,
      name: "Moni092",
      date: "31/12/2024",
      subject: " Nga Mihi",
      review:
        "My whānau and I love this app. It has helped us increase our use of Te Reo Māori at home and at Kōhanga.",
    },
    {
      id: 2,
      rating: 5,
      name: "Georgina",
      date: "05/11/2024",
      subject: "Google Play Store Review",
      review: "Love it. Hearing the phrases, helps with pronunciation.",
    },
    {
      id: 3,
      rating: 5,
      name: "Bob Bob",
      date: "19/10/2024",
      subject: "Google Play Store Review",
      review: "beautiful app love it 😀 Tu meke whanua",
    },
    {
      id: 4,
      rating: 5,
      name: "Doug Dunn",
      date: "04/10/2024",
      subject: "Google Play Store Review",
      review: "Rāwe",
    },
    {
      id: 5,
      rating: 5,
      name: "Shu Shu",
      date: "14/09/2024",
      subject: "Google Play Store Review",
      review:
        "A well designed app to help learn te reo Māori. It's straightforward and learning from this app has personally made me feel more in touch with my Māori culture ❤️",
    },
    {
      id: 6,
      rating: 5,
      name: "StoneCodeX",
      date: "15/08/2024",
      subject: "Request",
      review:
        "Hey love the app. But would really love if it there was a “phase/word of the day”, that is a push notification that gets sent once a day... Thanks for your mahi.",
    },
    {
      id: 7,
      rating: 5,
      name: "Therese Manning-Cook",
      date: "25/07/2024",
      subject: "Google Play Store Review",
      review: "Love the audio voice, great learning tool",
    },
    {
      id: 8,
      rating: 5,
      name: "Michelle Tait",
      date: "24/07/2024",
      subject: "Google Play Store Review",
      review: "Good app to help build your Te Reo confidence",
    },
    {
      id: 9,
      rating: 5,
      name: "Simon Marr",
      date: "04/04/2024",
      subject: "Google Play Store Review",
      review: "Awesome 👌",
    },
    {
      id: 10,
      rating: 5,
      name: "Peace & Love",
      date: "24/03/2024",
      subject: "Google Play Store Review",
      review: "kai pai to mahi. Kia ora",
    },
    {
      id: 11,
      rating: 5,
      name: "Sun Shine",
      date: "22/03/2024",
      subject: "Google Play Store Review",
      review: "Love this app. Nga mihi",
    },
    {
      id: 12,
      rating: 5,
      name: "Vivienne Rose",
      date: "17/02/2024",
      subject: "Google Play Store Review",
      review:
        "I love this app!!! I'm learning our reo and this app is helping me so much especially with pronunciation and tone! Ngā mihi nui!!",
    },
    {
      id: 13,
      rating: 5,
      name: "2ez Komene",
      date: "13/02/2024",
      subject: "Google Play Store Review",
      review: "E pai ana ki te ako te reo",
    },
    {
      id: 14,
      rating: 5,
      name: "Quorie",
      date: "11/02/2024",
      subject: "Google Play Store Review",
      review: "Mean maori mean",
    },
    {
      id: 15,
      rating: 5,
      name: "C.S SFl",
      date: "24/12/2023",
      subject: "Google Play Store Review",
      review: "so far it's been good",
    },
    {
      id: 16,
      rating: 5,
      name: "C J",
      date: "22/11/2023",
      subject: "Google Play Store Review",
      review:
        "Im signing up to a te ao maori course and i really like this app for a head start. Within 5 mins my kid who is 5 and i have learnt a few sentences. Definately worth it",
    },
    {
      id: 17,
      rating: 5,
      name: "wst888",
      date: "08/11/2023",
      subject: "Ako tahi",
      review: "Simply awesome,made easy to learn",
    },
    {
      id: 18,
      rating: 5,
      name: "Rahopuru",
      date: "29/09/2022",
      subject: "Review",
      review: "Amazing app! So helpful to hear full sentences. It would great if in the practice section there was a button to hear the audio as well. Highly recommend. Kia ora!",
    },
    {
      id: 19,
      rating: 5,
      name: "Moringa12",
      date: "14/08/2022",
      subject: "Educational",
      review: "Tumeke! helping me learn the importance of one’s language- Thank you 🙏🏻",
    },
    {
      id: 20,
      rating: 5,
      name: "Kuini Martha",
      date: "22/09/2018",
      subject: "Great Te Reo Maori App!!",
      review: "Awesome, now I can text my whanau and friends in Te Reo Maori..",
    },
    {
      id: 21,
      rating: 5,
      name: "Roger 5785",
      date: "17/09/2018",
      subject: "Superb for a starter",
      review: "A very nice tool for learning Maori for starters",
    }
  ];

  return (
    <section id="reviews" className="sp-t reviews-bg">
      <Container>
        <Col lg={6}>
          {/* - Banner Heading - */}
          <div className="title-heading">
            <div className="title-text mb-4">
              <div className="title-title">
                <h1>
                  Reviews & Ratings
                  <br />
                </h1>
              </div>
            </div>
          </div>
          {/* - Banner Heading - */}
        </Col>
        {/* Review Statistics */}
        <Row className="text-center">
          {reviewPlatforms.map((platform) => (
            <Col lg={6} key={platform.id} className="mb-4">
              <h3 style={{ color: "#272727" }}>{platform.platform}</h3>
              <p style={{ color: "#272727", fontSize: "1.2rem" }}>
                {platform.reviews}
              </p>
              <div style={{ color: "gold", fontSize: "1.5rem" }}>
                {Array.from({ length: 5 }, (_, i) => (
                  <FaStar key={i} />
                ))}
              </div>
              <p style={{ color: "#272727", fontSize: "1.2rem" }}>
                {platform.rating} out of 5 stars
              </p>
            </Col>
          ))}
        </Row>

        {/* Adaptable Cards */}
        <Row className="">
          {adaptableCards.map((card) => (
            <Col lg={4} md={6} sm={12} key={card.id} className="mb-4">
              <Card
                style={{
                  backgroundColor: "#f8f8f8",
                  borderRadius: "10px",
                  height: "270px",
                  padding: "15px",
                }}
              >
                <Card.Body className="text-left">
                  {/* Stars */}
                  <div
                    style={{
                      color: "gold",
                      fontSize: "1.5rem",
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    {Array.from({ length: card.rating }, (_, i) => (
                      <FaStar key={i} />
                    ))}
                  </div>

                  {/* Name and Date */}
                  <Card.Text
                    style={{
                      fontSize: "1rem",
                      color: "#666",
                      marginTop: "10px",
                    }}
                  >
                    <strong>{card.name}</strong> | {card.date}
                  </Card.Text>

                  {/* Subject */}
                  <Card.Text
                    style={{
                      fontSize: "1.1rem",
                      fontWeight: "bold",
                      color: "#444",
                      marginTop: "10px",
                    }}
                  >
                    {card.subject}
                  </Card.Text>

                  {/* Review Text */}
                  <Card.Text
                    style={{
                      fontSize: "1rem",
                      color: "#555",
                      marginTop: "10px",
                    }}
                  >
                    {card.review}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Reviews;
