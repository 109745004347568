import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BackToTop from "../components/BackToTop";
import Preloader from "../components/Preloader";
import LottieAnimation from "../components/LottieAnimation";
import AnimatedLogo from "../assets/animations/akotahi.json";

const Layout = ({ children }) => {
  const [isTimerComplete, setIsTimerComplete] = useState(false);
  const [startMoveAnimation, setStartMoveAnimation] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

    useEffect(() => {
      // Set a timer to start the move-to-corner animation
      const moveAnimationTimer = setTimeout(() => {
        setStartMoveAnimation(true);
      }, 3000); // Start animation after 3 seconds
    
      // Set a timer to complete the fade and switch views
      const completeTimer = setTimeout(() => {
        setIsTimerComplete(true);
      }, 5000); // Complete fade after 4.5 seconds
    
      // Cleanup timers on component unmount
      return () => {
        clearTimeout(moveAnimationTimer);
        clearTimeout(completeTimer);
      };
    }, []);

  return (
    <div>
    {!isTimerComplete ? (
      <div className="App">
        <div className={`fade-logo ${startMoveAnimation ? "move-to-corner" : ""}`}>
          <header className="App-header">
            <LottieAnimation
              animationData={AnimatedLogo}
              loop={false}
              autoplay={true}
            />
          </header>
        </div>
      </div>
    ) : (
      <div className="fade-logo fade-in-logo">
            <>
      {loading ? <Preloader /> : null}
      <Header />
      <main>{children}</main>
      <Footer />
      <BackToTop />
    </>
      </div>
    )}
  </div>
    
  );
};

export default Layout;
