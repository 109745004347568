import React from "react";
import { Container, Row, Col } from "react-bootstrap";
const bannerImage = require("../assets/images/1470x2020B.png");

const About = () => {
  return (
    <section className="sp-t about-bg" id="about">
      <Container>
        <Row className="align-items" style={{ paddingBottom:'80px' }}>
          <Col lg={6} className="mt-lg-0 mt-5 d-lg-block d-none">
            {/* - Banner Image - */}
            <div className="banner-image">
              <img src={bannerImage} alt="Mobile Banner" />
            </div>
            {/* - Banner Image - */}
          </Col>
          <Col lg={6}>
            {/* - Banner Heading - */}
            <div className="title-heading">
              <div className="title-text mb-4">
                <div className="title-title">
                  <h1>
                    Our Story
                    <br />
                  </h1>
                </div>
                <div className="banner-description">
                  <p style={{ fontSize: 18 }}>
                  The journey began six years ago with a simple question: “What is one thing we do every day that can help us learn Te Reo Māori?” The answer was clear: text messaging. It’s something we all do every day to connect with each other, making it the perfect tool to weave Te Reo Māori into our daily lives.
                  <br/>
                  <br/>
                  Initially, this app was created as a personal tool for a little family to learn Te Reo Māori together. However, realising its potential to help others on their language journey, the vision expanded. This decision marked the beginning of a challenging yet rewarding path, as the creator (of Ngātihine, Ngāpuhi, and Ngātiporou descent) was also on his own journey to learn Te Reo Māori, with guidance and support from his whānau.
                  <br />
                  <br/>
                  At the beginning of this journey, he reached out to numerous organisations for support in creating this app. However, many could not see the same vision for Ako Tahi. Undeterred, he pushed forward, relying on his whānau for translations and dedicating countless hours to teaching himself how to code. It wasn’t an easy path, but his belief in the potential of Ako Tahi to help others kept him going.
                  <br />
                  <br/>
                  Today, Ako Tahi stands as a grassroots project born from aroha, curiosity, and a commitment to supporting the learning and revitalisation of Te Reo Māori.
                  </p>
                </div>
              </div>
            </div>
            {/* - Banner Heading - */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
