import React from "react";
import { Container, Col, Row } from "react-bootstrap";
const bannerImage = require("../assets/images/akotahibanner.png");

const Footer = () => {
  return (
    <>
      <footer className="sp-b">
        <Container>
          <Row className="align-items">
            <Col className="mt-lg-0 d-lg-block d-none">
              {/* - Banner Image - */}
              <div className="banner-image" style={{ justifyContent:'center', display:'flex' }}>
                <img style={{ width:'50%' }} src={bannerImage} alt="Mobile Banner" />
              </div>
              {/* - Banner Image - */}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {/* - Copyright - */}
              <div
                className="copy-right text-center pt-5"
                style={{ fontWeight: "bold", fontSize: 20, paddingBottom: 50 }}
              >
                © AKO TAHI LIMITED 2025
              </div>
              {/* - Copyright - */}
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
