import React, { useEffect } from "react";
import { useLottie } from "lottie-react";

const LottieAnimation = ({ animationData, loop = false, autoplay = true, onComplete }) => {
  const options = {
    animationData,
    loop,
    autoplay,
  };

  const { View, animation } = useLottie(options);

  useEffect(() => {
    if (animation) {
      console.log("Animation initialized:", animation);
      animation.addEventListener("complete", onComplete);

      return () => {
        animation.removeEventListener("complete", onComplete);
      };
    }
  }, [animation, onComplete]);

  return (
    <div>
      {View || <div>Loading animation...</div>}
    </div>
  );
};

export default LottieAnimation;