import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const bannerImage = require("../assets/images/1470x2020C.png");

const Supporters = () => {
  return (
    <section
      className="sp-t supporters-bg"
      id="supporters"
    >
      <Container>
        <Row className="" style={{ paddingBottom:'80px' }}>
          <Col lg={6}>
            {/* - Banner Heading - */}
            <div className="banner-heading">
              <div className="banner-text mb-4">
                <div className="banner-title">
                  <h1 style={{ color:'white' }}>
                    Supporters
                  </h1>
                </div>

                <div className="banner-description">
                  <div className="container">
                    <p
                      style={{
                        fontSize: "1.2rem",
                        color: "white",
                        lineHeight: "1.8",
                        textAlign: "left",
                      }}
                    >
                      We extend our heartfelt gratitude to{" "}
                      <strong>
                        Te Huinga
                      </strong>{" "}
                      for their generous support of our kaupapa for Ako Tahi.
                      Their contribution has enabled us to access essential
                      resources, allowing us to improve the app and create a
                      better learning experience for everyone.
                      <br />
                      <br />
                      We also want to acknowledge and thank our iwi,{" "}
                      <strong>
                        Ngātihine
                      </strong>
                      , for their unwavering support. This collective effort has been instrumental in advancing our kaupapa, and we are deeply grateful for the shared commitment to preserving and promoting Te Reo Māori.
                    </p>
                    <br />
                    <br />
                    <p
                      style={{
                        fontSize: "1.2rem",
                        color: "white",
                        lineHeight: "1.8",
                        textAlign: "left",
                      }}
                    >
                      We are equally grateful to all those who contributed to our{" "}
                      <a
                        href="https://givealittle.co.nz/cause/ako-tahi-tamariki-a-te-reo-app-for-parents"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#5A5AFF", textDecoration: "underline" }}
                      >
                        Givealittle
                      </a>
                      {" "}page. It has been over two and a half years since we first launched the page, and while we were genuinely touched by the contributions, the amount raised at the time was unfortunately not sufficient to move this initiative forward. We made every effort to return contributions to those we could identify.
                      <br/>
                      <br/>
                      Now, with the generous support from Te Huinga, we are excited to finally move forward and bring this vision to life. This initiative supports the kaupapa of a new, free mobile app, Ako Tahi Tamariki—a Te Reo Māori app designed specifically for parents.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* - Banner Heading - */}
          </Col>

          <Col lg={6} className="mt-lg-0 mt-5 d-lg-block d-none">
            {/* - Banner Image - */}
            <div className="banner-image">
              <img src={bannerImage} alt="Mobile Banner" />
            </div>
            {/* - Banner Image - */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Supporters;
