import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

const logoImage = require("../assets/images/logo.png");
const logoImageWhite = require("../assets/images/logo-white.png");

const navItems = [
  { title: "Home", path: "home" },
  { title: "Our Story", path: "about" },
  { title: "Supporters", path: "supporters" },
  { title: "Reviews", path: "reviews" },
  { title: "Contact", path: "contact" },
];

const Header = () => {
  const [scrolling, setScrolling] = useState(false);
  const [openNav, setOpenNav] = useState(false);

  const handleNavigation = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const yOffset = -120; // Adjust this value to match your sticky header height
      const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: yPosition, behavior: "smooth" });
    }
    setOpenNav(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {/* - Site Header - */}
      <header className={`site-header d-lg-block d-none ${scrolling ? "sticky-header" : ""}`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className="header-content">
                {/* - Header Left - */}
                <div className="header-left">
                  <div className="site-logo">
                    <a href="#home">
                      <img src={!scrolling ? logoImageWhite : logoImage} alt="Site Logo" />
                    </a>
                  </div>
                </div>
                {/* - Header Left - */}

                {/* - Header Right - */}
                <div className="header-right">
                  <div className="site-navigation">
                    <ul>
                      {navItems.map((item) => (
                        <li key={item.path}>
                          <a
                            href={item.path}
                            style={{ color: scrolling ? "#444" : "white" }}
                            onClick={(e) => {
                              e.preventDefault(); // Prevent default anchor behavior
                              handleNavigation(item.path);
                            }}
                          >
                            {item.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* - Header Right - */}
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      {/* - Site Header - */}

      {/* - Site Mobile Header - */}
      <header className={`site-mobile-header d-block d-lg-none ${scrolling ? "sticky-header" : ""}`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className="header-content">
                {/* - Header Left - */}
                <div className="header-left">
                  <div className="site-logo">
                    <img src={logoImage} alt="Site Logo" onClick={() => handleNavigation("home")} />
                  </div>
                </div>
                {/* - Header Left - */}

                {/* - Header Right - */}
                <div className="header-right">
                  <div className="mobile-nav-toggle" onClick={() => setOpenNav(!openNav)}>
                    <i style={{ color: scrolling ? "#444" : "white" }} className="las la-bars"></i>
                  </div>
                </div>
                {/* - Header Right - */}
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      {/* - Site Mobile Header - */}

      {/* - Mobile Navigation - */}
      <div className={`mobile-navigation-wrap p-4 ${openNav ? "show" : ""}`}>
        <div className="nav-header">
          <i className="las la-times-circle hide-mobile-nav" onClick={() => setOpenNav(false)}></i>
        </div>

        {/* - Navigation Items - */}
        <div className="mobile-nav">
          <ul>
            {navItems.map((item) => (
              <li key={item.path}>
                <a
                  href={item.path}
                  style={{ color: "#272727" }}
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default anchor behavior
                    handleNavigation(item.path);
                  }}
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        {/* - Navigation Items - */}
      </div>
      {/* - Mobile Navigation - */}
    </>
  );
};

export default Header;