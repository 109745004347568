import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FaEnvelope, FaFacebook, FaInstagram } from "react-icons/fa";

const Contact = () => {
  return (
    <section
      className="sp-t contact-bg"
      id="contact"
      style={{ padding: "80px 0" }}
    >
      <Container>
        <Row className="">
          <Col>
            <div className="title-heading">
              <div className="title-text mb-4">
                <div className="title-title">
                  <h1 style={{ color:'white '}}>
                    Contact Us
                  </h1>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Col>
            <p style={{ fontSize: "1.2rem", color: "white", paddingBottom:'40px ' }}>
              We would love to hear from you! Reach out to us through email or
              follow us on our social media platforms.
            </p>
          </Col>

        <Row className="justify-content-center text-center">
          {/* Email */}
          <Col lg={4} md={6} sm={12} className="mb-4">
            <div className="contact-item">
              <a
                href="mailto:support@akotahi.nz"
                style={{ textDecoration: "none", color: "white" }}
              >
                <FaEnvelope
                  style={{
                    fontSize: "2rem",
                    color: "white",
                    marginBottom: "10px",
                  }}
                />
                <p style={{ fontSize: "1.1rem", color: "white" }}>
                  support@akotahi.nz
                </p>
              </a>
            </div>
          </Col>
          {/* Facebook */}
          <Col lg={4} md={6} sm={12} className="mb-4">
            <div className="contact-item">
              <a
                href="https://www.facebook.com/akotahi"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "white" }}
              >
                <FaFacebook
                  style={{
                    fontSize: "2rem",
                    color: "white",
                    marginBottom: "10px",
                  }}
                />
                <p style={{ fontSize: "1.1rem", color: "white" }}>Facebook</p>
              </a>
            </div>
          </Col>
          {/* Instagram */}
          <Col lg={4} md={6} sm={12} className="mb-4">
            <div className="contact-item">
              <a
                href="https://www.instagram.com/akotahi_nz/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "white" }}
              >
                <FaInstagram
                  style={{
                    fontSize: "2rem",
                    color: "white",
                    marginBottom: "10px",
                  }}
                />
                <p style={{ fontSize: "1.1rem", color: "white" }}>Instagram</p>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;