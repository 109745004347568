import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const bannerImage = require("../assets/images/1470x2020.png");
const appStore = "https://s3-ap-southeast-2.amazonaws.com/www.akotahi.nz/assets/images/apple_badge.png"
const playStore = "https://s3-ap-southeast-2.amazonaws.com/www.akotahi.nz/assets/images/google_badge.png"

const textSequence = [
  "Search for a",
  "Listen to the",
  "Share the",
  "Download",
];

const SiteBanner = () => {

  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [fadeState, setFadeState] = useState("fade-in"); // 'fade-in' or 'fade-out'

  useEffect(() => {
    if (currentTextIndex < textSequence.length - 1) {
      const fadeOutTimer = setTimeout(() => {
        setFadeState("fade-out"); // Start fade-out for current text
      }, 3000); // Visible for 3 seconds

      const fadeInTimer = setTimeout(() => {
        setCurrentTextIndex((prevIndex) => prevIndex + 1); // Move to next text
        setFadeState("fade-in"); // Start fade-in for new text
      }, 4000); // Fade-out completes after 1 second

      return () => {
        clearTimeout(fadeOutTimer);
        clearTimeout(fadeInTimer);
      };
    }
  }, [currentTextIndex]);

  return (
    <section className="site-banner" id="home">
      <Container>
        <Row className="align-items-center" style={{ paddingBottom:'80px' }}>
          <Col lg={6}>
            {/* - Banner Heading - */}
            <div className="banner-heading">
              <div className="banner-text mb-4">
                <div className="banner-title">
                <h1>
                    Learn Together.
                    <br />
                    <span
                      className={`animated-text ${fadeState}`}
                      style={{
                        opacity: fadeState === "fade-in" ? 1 : 0,
                      }}
                    >
                      {textSequence[currentTextIndex]}
                      {currentTextIndex === textSequence.length - 1 && (
                        <span
                          style={{
                            color: "black",
                            textShadow:
                              "1px 1px 3px rgba(255, 255, 255, 0.8)",
                          }}
                        >
                          {" "}
                          Ako Tahi.
                        </span>
                      )}
                      {currentTextIndex !== textSequence.length - 1 && (
                        <span
                          style={{
                            color: "black",
                            textShadow:
                              "1px 1px 3px rgba(255, 255, 255, 0.8)",
                          }}
                        >
                          {" "}
                          Phrase.
                        </span>
                      )}
                    </span>
                  </h1>
                </div>
                <div className="banner-description">
                  <p>
                  Ako Tahi is a free mobile app with over <strong>1,300 commonly used Te Reo Māori phrases</strong>, designed to help you learn, use, and share the language in your everyday life.
                  <br />
                  <br />
                  It’s a practical tool for effortlessly weaving Te Reo Māori into your daily routine – <strong>simple, accessible, and made for everyone.</strong>
                  </p>
                </div>
              </div>

              <div className="banner-download-buttons">
                <Link to="https://apps.apple.com/nz/app/ako-tahi/id1435115715" target="_blank">
                  <img src={appStore} alt="App Store" />
                </Link>
                <Link to="https://play.google.com/store/apps/details?id=com.akotahi&hl=en&gl=US" target="_blank">
                  <img src={playStore} alt="Play Store" />
                </Link>
              </div>
            </div>
            {/* - Banner Heading - */}
          </Col>

          <Col lg={6} className="mt-lg-0 mt-5 d-lg-block d-none">
            {/* - Banner Image - */}
            <div className="banner-image">
              <img src={bannerImage} alt="Mobile Banner" />
            </div>
            {/* - Banner Image - */}
          </Col>
        </Row>
      </Container>


      <style jsx>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        @keyframes fadeOut {
          from {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }

        .animated-text {
          display: inline-block;
          transition: opacity 1s ease-in-out;
        }

        .fade-in {
          animation: fadeIn 1s ease-in-out forwards;
        }

        .fade-out {
          animation: fadeOut 1s ease-in-out forwards;
        }
      `}</style>

    </section>
  );
};

export default SiteBanner;
