import React from "react";
import Layout from "../layout";
import SiteBanner from "../sections/SiteBanner";
import About from "../sections/About";
import Supporters from "../sections/Supporters";
import Reviews from "../sections/Reviews";
import Contact from "../sections/Contact";

const Home = () => {
  return (
    <Layout>
      <SiteBanner />
      <About />
      <Supporters />
      <Reviews />
      <Contact />
    </Layout>
  );
};

export default Home;
